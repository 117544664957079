export const licenseConstants = {
  LICENSE_CLEAR_STATUS_MESSAGE: 'LICENSE_CLEAR_STATUS_MESSAGE',
  LICENSE_REQUEST: 'LICENSE_REQUEST',
  LICENSE_SUCCESS: 'LICENSE_SUCCESS',
  LICENSE_FAILURE: 'LICENSE_FAILURE',

  LICENSE_DELETE_REQUEST: 'LICENSE_DELETE_REQUEST',
  LICENSE_DELETE_SUCCESS: 'LICENSE_DELETE_SUCCESS',
  LICENSE_DELETE_FAILURE: 'LICENSE_DELETE_FAILURE',

  LICENSE_UPLOAD_DEACTIVATED: 'LICENSE_UPLOAD_DEACTIVATED',
};
