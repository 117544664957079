import { licenseConstants } from "../@_constants/license.constants";

const initialState = {
  license: [],
  loading: false,
  message: "",
  status: {
    LICENSE_POST: "",
    LICENSE_DELETE: "",
  },
};

export function licenseReducer(state = initialState, action) {
  switch (action.type) {
    case licenseConstants.LICENSE_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        status: { ...state.status, [action.stateName]: "" },
      };
      break;
    case licenseConstants.LICENSE_UPLOAD_DEACTIVATED:
      state = {
        ...state,
        deactivated: action.data,
      };
      break;

    case licenseConstants.LICENSE_REQUEST:
      state = {
        ...state,
        loading: true,
        status: { ...state.status, LICENSE_POST: "request" },
      };
      break;
    case licenseConstants.LICENSE_SUCCESS:
      state = {
        ...state,
        license: action.data,
        loading: false,
        status: { ...state.status, LICENSE_POST: "success" },
      };
      break;
    case licenseConstants.LICENSE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, LICENSE_POST: "failed" },
      };
      break;
    case licenseConstants.LICENSE_DELETE_REQUEST:
      state = {
        ...state,
        loading: true,
        status: { ...state.status, LICENSE_DELETE: "request" },
      };
      break;
    case licenseConstants.LICENSE_DELETE_SUCCESS:
      state = {
        ...state,
        license: null,
        loading: false,
        status: { ...state.status, LICENSE_DELETE: "success" },
      };
      break;
    case licenseConstants.LICENSE_DELETE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        status: { ...state.status, LICENSE_DELETE: "failed" },
      };
      break;
    default:
      break;
  }
  return state;
}
