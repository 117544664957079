import { authConstants } from '@_constants/auth.constants';

let user = localStorage.getItem('user');
let caseNumber = localStorage.getItem('case_id');

const initialState = {
  authenticated: user ? true : false,
  loading: false,
  user: user,
  caseNumber: user ? caseNumber : null,
};

export function authReducer(state = initialState, action) {
  switch (action.type) {
    case authConstants.LOGIN_REQUEST:
      state = { ...state, loading: true, message: '' };
      break;

    case authConstants.LOGIN_SUCCESS:
      state = { ...state, loading: false, user: action.user };
      break;

    case authConstants.LOGIN_FAILURE:
      state = { ...state, loading: false, message: action.error, user: null };
      break;

    case authConstants.LOGOUT:
      state = { ...state, loading: false, user: null };
      break;

    default:
      break;
  }

  return state;
}
