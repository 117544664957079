import React from "react";
import styles from "./ResolveCaseSummary.module.css";
import ChargeBox from "@_components/ChargeBox/ChargeBox";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { useTranslation } from "react-i18next";
import OriginalImage from "@_components/OriginalImage/OriginalImage";
import { useSelector } from "react-redux";
import Close from "@material-ui/icons/Close";

function ResolveCaseSummary(props) {
  const { t } = useTranslation();
  const chargeDetails = useSelector((state) => state.chargeReducer);
  const caseInfo = useSelector((state) => state.caseDetailsReducer);

  const charge = chargeDetails.charge;

  return (
    <>
      {caseInfo.caseDetails.state === "PAID" ? (
        <>
          <Close
            onClick={() => {
              window.location.href = "/caseinfo";
            }}
            className={styles.mob_close_icon}
          ></Close>
          <div className={styles.payment_message_header}>
            <h2 className={styles.payment_message_header}>
              {t("_case_closed")}
            </h2>
            <FavoriteIcon />
          </div>

          <p>{t("_thank_you_for_resolving_this_matter_text")}</p>
          <p>{t("_thank_you_for_your_cooperation_and_understanding")}</p>
        </>
      ) : (
        <>
          <div className={styles.payment_info_container}>
            <h2 className={styles.payment_info_header}>{t("_resolve_case")}</h2>
            <Close
              onClick={() => {
                window.location.href = "/caseinfo";
              }}
              className={styles.mob_close_icon}
            ></Close>
          </div>
          <div className={styles.original_image_box}>
            {caseInfo.caseDetails.findings ? (
              caseInfo.caseDetails.findings.map((finding) => (
                <div key={finding.id}>
                  <OriginalImage
                    finding={finding}
                    currency={charge.currency}
                    country={caseInfo.caseDetails.country}
                  />
                </div>
              ))
            ) : (
              <div></div>
            )}
          </div>
          <ChargeBox country={caseInfo.caseDetails.country} />{" "}
        </>
      )}
    </>
  );
}

export default ResolveCaseSummary;
