import Config from '@_configs/api.config';
import { authHeader } from '../@_utils/helpers';
import { handleResponse } from '@_utils/helpers';

export const paymentService = {
  payment,
  getPayment,
};

function payment(finalAmount) {
  let payment = {
    displayed_payment_amount: finalAmount,
  };

  const requestOptions = {
    method: 'PUT',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
    //stringify
    body: JSON.stringify(payment),
  };

  return fetch(
    Config.API_ROOT +
      'cases/' +
      localStorage.getItem('case_id') +
      '/payment/setup',
    requestOptions
  ).then(handleResponse);
}

function getPayment() {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
  };

  return fetch(
    Config.API_ROOT + 'cases/' + localStorage.getItem('case_id') + '/payment',
    requestOptions
  ).then(handleResponse);
}
