import { alertConstants } from '@_constants/alert.constants';
const initialState = { type: 'none', message: '' };

export function alertReducer(state = initialState, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      state = {
        type: 'success',
        message: action.message,
      };
      break;
    case alertConstants.ERROR:
      state = {
        type: 'error',
        message: action.message,
      };
      break;
    case alertConstants.WARNING:
      state = {
        type: 'warning',
        message: action.message,
      };
      break;
    case alertConstants.CLEAR:
      state = initialState;
      break;
    default:
      break;
  }

  return state;
}
