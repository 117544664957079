import { createStore, applyMiddleware, compose } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunkMiddleware from "redux-thunk";
import { combineReducers } from "redux";

import { authReducer } from "@_reducers/auth.reducer";
import { alertReducer } from "@_reducers/alert.reducer";
import { caseDetailsReducer } from "@_reducers/case_details.reducer";
import { chargeReducer } from "@_reducers/charge.reducer";
import { paymentReducer } from "@_reducers/payment.reducer";
import { licenseReducer } from "@_reducers/license.reducer";
import { billingInfoReducer } from "@_reducers/billing_info.reducer";
import { customerReducer } from "@_reducers/customer.reducer";

import config from "../config.json";
import { invoiceReducer } from "@_reducers/invoice.reducer";

const appReducer = combineReducers({
  authReducer,
  alertReducer,
  caseDetailsReducer,
  chargeReducer,
  paymentReducer,
  licenseReducer,
  billingInfoReducer,
  customerReducer,
  invoiceReducer,
});

const rootReducer = (state, action) => {
  if (action.type === "USER_LOGOUT") {
    state = undefined;
  }
  return appReducer(state, action);
};

export const Store = createStore(
  rootReducer,
  config.debug_mode
    ? compose(applyMiddleware(thunkMiddleware), composeWithDevTools())
    : compose(applyMiddleware(thunkMiddleware))
);
