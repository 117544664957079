import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { PrivateRoute } from "./PrivateRoute";
import { history } from "@_utils/helpers";

import CaseInfo from "../@_pages/CaseInfo/CaseInfo";
import Layout from "@_pages/Layout/Layout";
import LoginPage from "@_pages/Login";
import AboutUs from "../@_pages/AboutUs/AboutUs";
import FAQ from "../@_pages/FAQ/FAQ";
import PrivacyPolicy from "../@_pages/PrivacyPolicy/PrivacyPolicy";
import PaymentConfirmation from "../@_pages/PaymentConfirmation/PaymentConfirmation";
import LicenseConfirmation from "../@_pages/LicenseConfirmation/LicenseConfirmation";
import ResolveCaseMobile from "@_pages/ResolveCaseMobile/ResolveCaseMobile";
import LicenseMobile from "@_pages/LicenseMobile/LicenseMobile";
import ResolveCaseSummary from "@_components/popups/ResolveCaseSummary/ResolveCaseSummary";
import ResolveCase from "@_components/ResolveCase/ResolveCase";

function Routes() {
  return (
    <div>
      <Router history={history}>
        <Layout>
          <Switch>
            <Route path="/login" component={LoginPage} />
            <PrivateRoute exact path="/caseinfo" component={CaseInfo} />
            <Route exact path="/about" component={AboutUs} />
            <Route exact path="/FAQ" component={FAQ} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <PrivateRoute
              exact
              path="/payment-confirmation"
              component={PaymentConfirmation}
            />
            <PrivateRoute
              exact
              path="/upload-success"
              component={LicenseConfirmation}
            />
            <PrivateRoute
              exact
              path="/resolve-case"
              component={ResolveCaseMobile}
            />
            <PrivateRoute
              exact
              path="/resolve-case-info"
              component={ResolveCase}
            />
            <PrivateRoute
              exact
              path="/resolve-case-summary"
              component={ResolveCaseSummary}
            />
            <PrivateRoute
              exact
              path="/upload-license"
              component={LicenseMobile}
            />
            <Redirect from="*" to="/caseinfo" />
          </Switch>
        </Layout>
      </Router>
    </div>
  );
}

export default Routes;
