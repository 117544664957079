import { paymentConstants } from '../@_constants/payment.constants';

const initialState = {
  payment: {},
  paymentDetails: {},
  loading: false,
  message: '',
};

export function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case paymentConstants.PAYMENT_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case paymentConstants.PAYMENT_SUCCESS:
      state = {
        ...state,
        payment: action.data,
        loading: false,
      };
      break;
    case paymentConstants.PAYMENT_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
      };
      break;
    case paymentConstants.PAYMENT_GET_REQUEST:
      state = { ...state, loading: true, message: '' };
      break;

    case paymentConstants.PAYMENT_GET_SUCCESS:
      state = {
        ...state,
        loading: false,
        paymentDetails: action.data,
      };
      break;

    case paymentConstants.PAYMENT_GET_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
      };
      break;
    default:
      break;
  }
  return state;
}
