import React, { useEffect, useState } from "react";
import Payment from "@_components/popups/Payment/Payment";
import Address from "@_components/popups/Address/Address";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { invoiceActions } from "@_actions/invoice.actions";

function ResolveCaseMobile(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const invoiceReducer = useSelector((state) => state.invoiceReducer);
  const [pay, setPay] = useState(false);

  useEffect(() => {
    if (invoiceReducer.status.INVOICE_GENERATE === "success") {
      handleClosePage();
      dispatch(invoiceActions.clearStatusMessage("INVOICE_GENERATE"));
    }
  }, [invoiceReducer.status.INVOICE_GENERATE, dispatch]);

  const handleClosePage = () => {
    window.location.href = "/caseinfo";
  };

  const handleGenerateInvoice = () => {
    dispatch(invoiceActions.generateInvoice());
  };

  return (
    <div>
      {pay ? (
        <Payment onClose={handleClosePage} />
      ) : (
        <Address
          onClose={handleClosePage}
          button={
            props.location.state === "invoice"
              ? t("_generate_and_download_button")
              : t("_purchase_license")
          }
          next={
            props.location.state === "invoice"
              ? () => handleGenerateInvoice()
              : () => setPay(true)
          }
        />
      )}
    </div>
  );
}

export default ResolveCaseMobile;
