import React from "react";
import styles from "./InfoPopup.module.css";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";

const InfoPopup = (props) => {
  const { title } = props;
  return (
    <Dialog open={props.open} onClose={() => props.close()}>
      <DialogTitle>
        {" "}
        <span>{title}</span>
      </DialogTitle>
      <DialogContent>
        {" "}
        <div className={styles.container}>{props.children}</div>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.close}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
};

export default InfoPopup;
