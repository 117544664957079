import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./FileUpload.module.css";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useTranslation } from "react-i18next";
import { licenseActions } from "@_actions/license.actions";
import { formatCurrency } from "@_utils/helpers";

function FileUpload(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [file, setFile] = useState();
  const [fileName, setFileName] = useState("");
  const [note, setNote] = useState("");
  const [licenseId, setLicenseId] = useState("");
  const caseDetails = useSelector((state) => state.caseDetailsReducer);

  let findingId = props.finding.id;

  useEffect(() => {
    props.parentCallback({ file, note, findingId });
  }, [file, note, findingId, props]);

  useEffect(() => {
    if (props.finding && props.finding.license) {
      setLicenseId(props.finding.license.id);
    } else {
      setLicenseId("");
    }
  }, [licenseId, props.finding]);

  const handleFileData = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    props.parentCallback({ file, note, findingId });
  };

  const handleResetFile = () => {
    setFile("");
    setFileName("");
  };

  const MAX_LENGTH = 20;

  const fileNameLength = (fileName) => {
    if (fileName.length > MAX_LENGTH) {
      return <>{`${fileName.substring(0, MAX_LENGTH)}...`}</>;
    } else return <>{fileName}</>;
  };

  useEffect(() => {
    if (file) {
      dispatch(licenseActions.deactivateLicenseUpload(false));
    } else {
      dispatch(licenseActions.deactivateLicenseUpload(true));
    }
  }, [dispatch, file]);

  let country = caseDetails.caseDetails && caseDetails.caseDetails.country;

  return (
    <div>
      <div className={styles.original_image_container}>
        <div className={styles.image_box}>
          <img
            src={props.finding.original_image.original_image_url}
            className={styles.original_image}
            alt="original img"
          />
        </div>
        <div className={styles.original_image_text}>
          <p>
            {t("_price")}:{" "}
            {formatCurrency(country, props.currency, props.finding.price)}
          </p>
          <p>
            {t("_picture_id")}: {props.finding.original_image.filename}
          </p>
        </div>
        <div className={styles.file_note_container}>
          {licenseId ? (
            <>
              <p>{t("_license_uploaded")}</p>
            </>
          ) : (
            <>
              <div className={styles.file_note_row}>
                {fileName ? (
                  <div>{fileNameLength(fileName)}</div>
                ) : (
                  <div className={styles.upload_file_btn_wrap}>
                    <input
                      accept=".pdf, .jpg, .jpeg, .gif, .png"
                      className={styles.input}
                      id="contained-button-file"
                      multiple
                      type="file"
                      style={{ display: "none" }}
                      onChange={handleFileData}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        component="span"
                        className={styles.upload_btn}
                      >
                        {t("_upload")}
                      </Button>
                    </label>
                  </div>
                )}
                <div
                  hidden={!fileName}
                  onClick={handleResetFile}
                  className={styles.clear_icon}
                >
                  <ClearIcon />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <div>
        <TextField
          id="outlined-multiline-static"
          label={t("_add_note")}
          multiline
          rows={4}
          fullWidth
          variant="outlined"
          value={note}
          onChange={(e) => {
            setNote(e.target.value);
          }}
        />
      </div>
    </div>
  );
}

export default FileUpload;
