import React, { useEffect, useState } from "react";
import styles from "./ChargeBox.module.css";
import { useSelector } from "react-redux";
import classnames from "classnames";
import InfoIcon from "@material-ui/icons/Info";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "@_utils/helpers";

function ChargeBox(props) {
  const { t } = useTranslation();
  const [indirectTax, setIndirectTax] = useState("");
  const payment = useSelector((state) => state.chargeReducer);
  let charge = payment.charge;
  const additionalFees = charge.additional_fees_no_settlement;

  function convertDate(inputFormat) {
    function pad(s) {
      return s < 10 ? "0" + s : s;
    }
    var d = new Date(inputFormat);
    return [pad(d.getDate()), pad(d.getMonth() + 1), d.getFullYear()].join("/");
  }

  useEffect(() => {
    if (charge.indirect_tax_type) {
      setIndirectTax(charge.indirect_tax_type);
    } else return;
  }, [charge.indirect_tax_type]);

  return (
    <div className={styles.charge_box}>
      {charge.settlement_offer &&
      Date.parse(charge.settlement_offer.settlement_date) >= new Date() ? (
        <>
          <div className={styles.charge_row}>
            <div className={styles.charge_column}>
              <p className={styles.label}>{t("_net_license_fee")}</p>
              <p className={styles.field}>
                {formatCurrency(
                  props.country,
                  charge.currency,
                  Number(charge.total_net_amount_findings)
                )}
              </p>
            </div>
          </div>

          {additionalFees
            ? additionalFees.map((additionalFee, i) =>
                additionalFee.taxable ? (
                  <div className={styles.charge_row} key={i}>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>{t("_additional_fee")}</p>
                      <p className={styles.field}>{additionalFee.name}</p>
                    </div>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>{t("_percentage")}</p>
                      <p className={styles.field}>
                        {additionalFee.percentage} %
                      </p>
                    </div>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>
                        {t("_additional_fee_amount")}
                      </p>
                      <p className={styles.field}>
                        {formatCurrency(
                          props.country,
                          charge.currency,
                          additionalFee.amount
                        )}
                      </p>
                    </div>
                  </div>
                ) : null
              )
            : null}
          {indirectTax === "VAT" && (
            <div className={styles.charge_row}>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_vat")}</p>
                <p className={styles.field}>
                  {charge.indirect_tax_no_settlement.percentage} %
                </p>
              </div>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_vat_amount")}</p>
                <p className={styles.field}>
                  {formatCurrency(
                    props.country,
                    charge.currency,
                    charge.indirect_tax_no_settlement.amount
                  )}
                </p>
              </div>
            </div>
          )}
          {indirectTax === "GST" && (
            <div className={styles.charge_row}>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_gst")}</p>
                <p className={styles.field}>
                  {charge.indirect_tax_no_settlement.percentage} %
                </p>
              </div>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_gst_amount")}</p>
                <p className={styles.field}>
                  {formatCurrency(
                    props.country,
                    charge.currency,
                    charge.indirect_tax_no_settlement.amount
                  )}
                </p>
              </div>
            </div>
          )}
          {indirectTax === "SALES_TAX" && (
            <div className={styles.charge_row}>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_sales_tax")}</p>
                <p className={styles.field}>
                  {charge.indirect_tax_no_settlement.percentage} %
                </p>
              </div>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_sales_tax_amount")}</p>
                <p className={styles.field}>
                  {formatCurrency(
                    props.country,
                    charge.currency,
                    charge.indirect_tax_no_settlement.amount
                  )}
                </p>
              </div>
            </div>
          )}

          {additionalFees
            ? additionalFees.map((additionalFee, i) =>
                !additionalFee.taxable ? (
                  <div className={styles.charge_row} key={i}>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>{t("_additional_fee")}</p>
                      <p className={styles.field}>{additionalFee.name}</p>
                    </div>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>{t("_percentage")}</p>
                      <p className={styles.field}>
                        {additionalFee.percentage} %
                      </p>
                    </div>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>
                        {t("_additional_fee_amount")}
                      </p>
                      <p className={styles.field}>
                        {formatCurrency(
                          props.country,
                          charge.currency,
                          additionalFee.amount
                        )}
                      </p>
                    </div>
                  </div>
                ) : null
              )
            : null}

          <div className={styles.charge_row}>
            <div className={styles.charge_column}>
              {indirectTax === "VAT" && additionalFees && (
                <p className={styles.label}>{t("_gross_incl_vat_and_fees")}</p>
              )}
              {indirectTax === "VAT" && !additionalFees && (
                <p className={styles.label}>{t("_gross_incl_vat")}</p>
              )}

              {indirectTax === "GST" && additionalFees && (
                <p className={styles.label}>{t("_gross_incl_gst_and_fees")}</p>
              )}
              {indirectTax === "GST" && !additionalFees && (
                <p className={styles.label}>{t("_gross_incl_gst")}</p>
              )}

              {indirectTax === "SALES_TAX" && additionalFees && (
                <p className={styles.label}>
                  {t("_gross_incl_sales_tax_and_fees")}
                </p>
              )}
              {indirectTax === "SALES_TAX" && !additionalFees && (
                <p className={styles.label}>{t("_gross_incl_sales_tax")}</p>
              )}
              {indirectTax === "NO_INDIRECT_TAX" ||
                (indirectTax === "REVERSE_CHARGE" && (
                  <p className={styles.label}>{t("_total")}:</p>
                ))}

              <p className={styles.field}>
                {formatCurrency(
                  props.country,
                  charge.currency,
                  charge.final_amount_gross_no_settlement
                )}
              </p>
            </div>
          </div>
          <div className={classnames(styles.discount_container)}>
            <div className={styles.discount_title}>
              <InfoIcon />
              <span className={styles.discount_title_text}>
                {t("_our_settlement_offer")}
              </span>
            </div>
            <div className={styles.charge_row_discount}>
              <div className={styles.charge_column_discount}>
                <p className={styles.label}>{t("_settlement_discount")}</p>
              </div>
              <div className={styles.charge_column_discount}>
                <p className={styles.field}>
                  {" "}
                  {charge.settlement_offer.settlement_percentage} %
                </p>
              </div>
            </div>
            <div className={styles.charge_row_discount}>
              <div className={styles.charge_column_discount}>
                <p className={styles.label}>{t("_settlement_deadline")}</p>
              </div>
              <div className={styles.charge_column_discount}>
                <p className={styles.field}>
                  {convertDate(charge.settlement_offer.settlement_date)}
                </p>
              </div>
            </div>
            <div className={styles.empty_row}></div>
            <div className={styles.charge_column}>
              {indirectTax === "VAT" && additionalFees && (
                <p className={styles.label}>
                  {t("_discounted_gross_incl_vat_and_fees")}
                </p>
              )}
              {indirectTax === "VAT" && !additionalFees && (
                <p className={styles.label}>
                  {t("_discounted_gross_incl_vat")}
                </p>
              )}

              {indirectTax === "GST" && additionalFees && (
                <p className={styles.label}>
                  {t("_discounted_gross_incl_gst_and_fees")}
                </p>
              )}
              {indirectTax === "GST" && !additionalFees && (
                <p className={styles.label}>
                  {t("_discounted_gross_incl_gst")}
                </p>
              )}

              {indirectTax === "SALES_TAX" && additionalFees && (
                <p className={styles.label}>
                  {t("_discounted_gross_incl_sales_tax_and_fees")}
                </p>
              )}
              {indirectTax === "SALES_TAX" && !additionalFees && (
                <p className={styles.label}>
                  {t("_discounted_gross_incl_sales_tax")}
                </p>
              )}
              {indirectTax === "NO_INDIRECT_TAX" ||
                (indirectTax === "REVERSE_CHARGE" && (
                  <p className={styles.label}>{t("_total")}:</p>
                ))}
              <p className={styles.total_field}>
                {formatCurrency(
                  props.country,
                  charge.currency,
                  charge.final_amount_gross_settlement
                )}
              </p>
            </div>
          </div>
        </>
      ) : (
        <>
          {/* if no settlement offer */}
          <div className={styles.charge_row}>
            <div className={styles.charge_column}>
              <p className={styles.label}>{t("_net_license_fee")}</p>
              <p className={styles.field}>
                {formatCurrency(
                  props.country,
                  charge.currency,
                  charge.total_net_amount_findings
                )}
              </p>
            </div>
          </div>
          {additionalFees
            ? additionalFees.map((additionalFee, i) =>
                additionalFee.taxable ? (
                  <div className={styles.charge_row} key={i}>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>{t("_additional_fee")}</p>
                      <p className={styles.field}>{additionalFee.name}</p>
                    </div>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>{t("_percentage")}</p>
                      <p className={styles.field}>
                        {additionalFee.percentage} %
                      </p>
                    </div>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>
                        {t("_additional_fee_amount")}
                      </p>
                      <p className={styles.field}>
                        {formatCurrency(
                          props.country,
                          charge.currency,
                          additionalFee.amount
                        )}
                      </p>
                    </div>
                  </div>
                ) : null
              )
            : null}

          {indirectTax === "VAT" && (
            <div className={styles.charge_row}>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_vat")}</p>
                <p className={styles.field}>
                  {charge.indirect_tax_no_settlement.percentage} %
                </p>
              </div>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_vat_amount")}</p>
                <p className={styles.field}>
                  {formatCurrency(
                    props.country,
                    charge.currency,
                    charge.indirect_tax_no_settlement.amount
                  )}
                </p>
              </div>
            </div>
          )}

          {indirectTax === "GST" && (
            <div className={styles.charge_row}>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_gst")}</p>
                <p className={styles.field}>
                  {charge.indirect_tax_no_settlement.percentage} %
                </p>
              </div>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_gst_amount")}</p>
                <p className={styles.field}>
                  {formatCurrency(
                    props.country,
                    charge.currency,
                    charge.indirect_tax_no_settlement.amount
                  )}
                </p>
              </div>
            </div>
          )}

          {indirectTax === "SALES_TAX" && (
            <div className={styles.charge_row}>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_sales_tax")}</p>
                <p className={styles.field}>
                  {charge.indirect_tax_no_settlement.percentage} %
                </p>
              </div>
              <div className={styles.charge_column}>
                <p className={styles.label}>{t("_sales_tax_amount")}</p>
                <p className={styles.field}>
                  {formatCurrency(
                    props.country,
                    charge.currency,
                    charge.indirect_tax_no_settlement.amount
                  )}
                </p>
              </div>
            </div>
          )}

          {additionalFees
            ? additionalFees.map((additionalFee, i) =>
                !additionalFee.taxable ? (
                  <div className={styles.charge_row} key={i}>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>{t("_additional_fee")}</p>
                      <p className={styles.field}>{additionalFee.name}</p>
                    </div>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>{t("_percentage")}</p>
                      <p className={styles.field}>
                        {additionalFee.percentage} %
                      </p>
                    </div>
                    <div className={styles.charge_column}>
                      <p className={styles.label}>
                        {t("_additional_fee_amount")}
                      </p>
                      <p className={styles.field}>
                        {formatCurrency(
                          props.country,
                          charge.currency,
                          additionalFee.amount
                        )}
                      </p>
                    </div>
                  </div>
                ) : null
              )
            : null}
          <div
            className={classnames(styles.charge_row, styles.charge_row_total)}
          >
            <div className={styles.charge_column}>
              {indirectTax === "VAT" && additionalFees && (
                <p className={styles.label}>{t("_gross_incl_vat_and_fees")}</p>
              )}
              {indirectTax === "VAT" && !additionalFees && (
                <p className={styles.label}>{t("_gross_incl_vat")}</p>
              )}

              {indirectTax === "GST" && additionalFees && (
                <p className={styles.label}>{t("_gross_incl_gst_and_fees")}</p>
              )}
              {indirectTax === "GST" && !additionalFees && (
                <p className={styles.label}>{t("_gross_incl_gst")}</p>
              )}

              {indirectTax === "SALES_TAX" && additionalFees && (
                <p className={styles.label}>
                  {t("_gross_incl_sales_tax_and_fees")}
                </p>
              )}
              {indirectTax === "SALES_TAX" && !additionalFees && (
                <p className={styles.label}>{t("_gross_incl_sales_tax")}</p>
              )}
              {indirectTax === "NO_INDIRECT_TAX" ||
                (indirectTax === "REVERSE_CHARGE" && (
                  <p className={styles.label}>{t("_total")}:</p>
                ))}
              <p className={styles.total_field}>
                {formatCurrency(
                  props.country,
                  charge.currency,
                  charge.final_amount_gross_no_settlement
                )}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default ChargeBox;
