import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./License.module.css";
import { useSelector, useDispatch } from "react-redux";
import FileUpload from "../../FileUpload/FileUpload";
import { useHistory } from "react-router-dom";
import { licenseActions } from "../../../@_actions/license.actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { caseDetailsActions } from "@_actions/case_details.actions";

function License(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [fileObject, setFileObject] = useState({});
  const history = useHistory();
  const chargeDetails = useSelector((state) => state.chargeReducer);
  const license = useSelector((state) => state.licenseReducer);

  let finding = props.finding;

  let callbackFunction = (childData) => {
    let newArray = fileObject;
    newArray[childData.findingId] = childData;
    setFileObject(newArray);
  };

  let charge = chargeDetails.charge;

  const handleSubmitLicense = (e) => {
    e.preventDefault();

    Object.keys(fileObject).forEach((id) => {
      let formData = new FormData();

      if (fileObject[id].file) {
        formData.append("license_file", fileObject[id].file);
        if (fileObject[id].note) formData.append("note", fileObject[id].note);
        dispatch(licenseActions.license(formData, id));
      }
    });
  };

  useEffect(() => {
    if (license.status.LICENSE_POST === "success") {
      dispatch(caseDetailsActions.caseDetails());
      history.push("/upload-success");
      setTimeout(() => {
        dispatch(licenseActions.clearStatusMessage("LICENSE_POST"));
      }, 2000);
    }
  });

  return (
    <div className={styles.license_container}>
      <div className={styles.license_header}>
        <h2>{t("_i_own_a_license")}</h2>
      </div>
      <div className={styles.license_body}>
        <div>
          {finding ? (
            <FileUpload
              finding={finding}
              currency={charge.currency}
              parentCallback={callbackFunction}
            />
          ) : (
            <div></div>
          )}
        </div>
        <div className={styles.upload_license_btn_container}>
          {license.loading ? (
            <CircularProgress />
          ) : (
            <div className={styles.upload_license_btn_wrap}>
              {license.deactivated && (
                <button className={styles.upload_license_btn_disabled} disabled>
                  {t("_submit_license")}
                </button>
              )}

              {!license.deactivated && (
                <button
                  className={styles.upload_license_btn}
                  onClick={handleSubmitLicense}
                >
                  {t("_submit_license")}
                </button>
              )}

              {license.message === "400" && (
                <p className={styles.upload_license_error}>
                  {t("_unsupported_file_format")}
                </p>
              )}
              {license.message && license.message !== "400" && (
                <p className={styles.upload_license_error}>
                  {t("_failed_to_upload_license")}
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default License;
