import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Error.module.css";
import { useSelector } from "react-redux";
import ErrorImage from "@_assets/images/embarrassed.png";

function Error() {
  const { t } = useTranslation();
  const customerDetails = useSelector((state) => state.customerReducer);

  return (
    <div className={styles.error_page_container}>
      <div className={styles.error_page_text}>
        <h1 className={styles.error_oops}>{t("_oops")}</h1>
        <h2>{customerDetails.message.error_message}</h2>
      </div>
      <div className={styles.image_container}>
        <img
          src={ErrorImage}
          alt="embarrassed dinosaur"
          className={styles.error_image}
        />
        <div className={styles.icon_credit}>
          {"icons made by "}
          <a href="https://www.freepik.com" title="Freepik">
            Freepik
          </a>{" "}
          {t("_from")}{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            www.flaticon.com
          </a>
        </div>
      </div>
    </div>
  );
}

export default Error;
