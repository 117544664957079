import { licenseConstants } from '@_constants/license.constants';
import { licenseService } from '@_services/license.service';

export const licenseActions = {
  license,
  deleteLicense,
  clearStatusMessage,
  deactivateLicenseUpload,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return { type: licenseConstants.LICENSE_CLEAR_STATUS_MESSAGE, stateName };
  }
}

function deactivateLicenseUpload(data) {
  return (dispatch) => {
    dispatch(clear(data));
  };

  function clear(data) {
    return { type: licenseConstants.LICENSE_UPLOAD_DEACTIVATED, data };
  }
}


function license(formData, id) {
  return (dispatch) => {
    dispatch(request());
    licenseService.license(formData, id).then(
      (data) => {
        let newData = {
          formData,
          id,
        };
        dispatch(success(newData, id));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };
}

function request() {
  return { type: licenseConstants.LICENSE_REQUEST };
}
function success(data) {
  return { type: licenseConstants.LICENSE_SUCCESS, data };
}
function failure(error) {
  return { type: licenseConstants.LICENSE_FAILURE, error };
}

function deleteLicense(finding_id) {
  return (dispatch) => {
    dispatch(deleteRequest);
    licenseService.deleteLicense(finding_id).then(
      (data) => {
        dispatch(deleteSuccess(data));
      },
      (error) => {
        console.log(error);
        dispatch(deleteFailure(error.toString()));
      }
    );
  };
}

function deleteRequest() {
  return { type: licenseConstants.LICENSE_DELETE_REQUEST };
}
function deleteSuccess(data) {
  return { type: licenseConstants.LICENSE_DELETE_SUCCESS, data };
}
function deleteFailure(error) {
  return { type: licenseConstants.LICENSE_DELETE_FAILURE, error };
}
