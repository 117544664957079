import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import styles from "./FAQ.module.css";
import BackBtn from "@_components/BackBtn/BackBtn";
import { useSelector, useDispatch } from "react-redux";
import { customerActions } from "@_actions/customer.actions";

function FAQ() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customerDetails = useSelector((state) => state.customerReducer);

  useEffect(() => {
    dispatch(customerActions.customer(window.location.hostname));
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.FAQ}>
        <BackBtn />
        <h2>{t("_faq")}</h2>
        {customerDetails.customer.faq &&
          customerDetails.customer.faq.map((faq, i) => (
            <div className={styles.text_container} key={i}>
              <h2>{faq.question}</h2>
              <p style={{ whiteSpace: "pre-line" }}>{faq.answer}</p>
            </div>
          ))}
      </div>
    </div>
  );
}

export default FAQ;
