import { paymentConstants } from "@_constants/payment.constants";
import { paymentService } from "@_services/payment.service";

export const paymentActions = {
  payment,
  getPayment,
};

function payment(finalAmount) {
  return (dispatch) => {
    dispatch(request());
    paymentService.payment(finalAmount).then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };
}

function request() {
  return { type: paymentConstants.PAYMENT_REQUEST };
}
function success(data) {
  return { type: paymentConstants.PAYMENT_SUCCESS, data };
}
function failure(error) {
  return { type: paymentConstants.PAYMENT_FAILURE, error };
}


function getPayment() {
  return (dispatch) => {
    dispatch(getRequest);
    paymentService.getPayment().then(
      (data) => {
        dispatch(getSuccess(data));
      },
      (error) => {
        console.log(error);
        dispatch(getFailure(error.toString()));
      }
    );
  };
}

function getRequest() {
  return { type: paymentConstants.PAYMENT_GET_REQUEST };
}
function getSuccess(data) {
  return { type: paymentConstants.PAYMENT_GET_SUCCESS, data };
}
function getFailure(error) {
  return { type: paymentConstants.PAYMENT_GET_FAILURE, error };
}
