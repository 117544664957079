export const invoiceConstants = {
  INVOICE_DOWNLOAD_REQUEST: "INVOICE_DOWMLOAD_REQUEST",
  INVOICE_DOWNLOAD_SUCCESS: "INVOICE_DOWMLOAD_SUCCESS",
  INVOICE_DOWNLOAD_FAILURE: "INVOICE_DOWMLOAD_FAILURE",

  // clear status
  INVOICE_CLEAR_STATUS_MESSAGE: "TEMPLATE_CLEAR_STATUS_MESSAGE",

  INVOICE_GENERATE_REQUEST: "INVOICE_GENERATE_REQUEST",
  INVOICE_GENERATE_SUCCESS: "INVOICE_GENERATE_SUCCESS",
  INVOICE_GENERATE_FAILURE: "INVOICE_GENERATE_FAILURE",

  ACTIVE_INVOICE: "ACTIVE",
  CANCELLED_INVOICE: "CANCELLED",
  CANCELLATION_INVOICE: "CANCELLATION",
};
