import { billingInfoConstants } from "@_constants/billing_info.constants";
import { billingInfoService } from "@_services/billing_info.service";

export const billingInfoActions = {
  billingInfo,
  reset
};

function billingInfo(billingDetails) {
  return (dispatch) => {
    dispatch(request());
    billingInfoService.billingInfo(billingDetails).then(
      (data) => {
        dispatch(success(billingDetails));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };
}

function request() {
  return { type: billingInfoConstants.BILLING_INFO_REQUEST };
}
function success(data) {
  return { type: billingInfoConstants.BILLING_INFO_SUCCESS, data };
}
function failure(error) {
  return { type: billingInfoConstants.BILLING_INFO_FAILURE, error };
}

function reset() {
  return { type: billingInfoConstants.BILLING_INFO_RESET };
}
