import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MobileStepper from "@material-ui/core/MobileStepper";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import styles from "./MobileStepper.module.css";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import { formatCurrency } from "@_utils/helpers";

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: "flex",
    alignItems: "center",
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    height: 255,
    maxWidth: 400,
    overflow: "hidden",
    display: "block",
    width: "100%",
  },
}));

export default function TextMobileStepper(props) {
  const { t } = useTranslation();
  const caseInfo = useSelector((state) => state.caseDetailsReducer);
  const payment = useSelector((state) => state.paymentReducer);

  let finding = props.finding;
  let country = caseInfo.caseDetails && caseInfo.caseDetails.country;

  const steps = [
    {
      label: `${t("_our_image")}`,
      imgPath: finding.original_image.original_image_url,
    },
    {
      label: `${t("_page_screenshot")}`,
      imgPath: finding.page_screenshot_url,
    },
  ];

  const classes = useStyles();
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className={styles.stepper_container}>
      <div className={classes.root}>
        <Paper square elevation={0} className={classes.header}>
          <Typography>{steps[activeStep].label}</Typography>
        </Paper>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {steps.map((step, index) => (
            <div key={step.label} className={styles.image_box}>
              {Math.abs(activeStep - index) <= 2 ? (
                <img
                  className={styles.image}
                  src={step.imgPath}
                  alt={step.label}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              {activeStep === 0 ? (
                <p className={styles.btn_text}>{t("_our_image")}</p>
              ) : (
                <></>
              )}
              {activeStep === 1 ? (
                <p className={styles.btn_text}>{t("_page_screenshot")}</p>
              ) : (
                <></>
              )}
              {activeStep === 2 ? <></> : <></>}
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              {activeStep === 0 ? <></> : <></>}
              {activeStep === 1 ? (
                <p className={styles.btn_text}>{t("_our_image")}</p>
              ) : (
                <></>
              )}
              {activeStep === 2 ? (
                <p className={styles.btn_text}>{t("_page_screenshot")}</p>
              ) : (
                <></>
              )}
            </Button>
          }
        />
      </div>
      <div>
        <div>
          {/* license valid */}
          {finding.license && finding.license.state === "VALID" ? (
            <div className={styles.image_text}>
              <p>{t("_uploaded_license_approved")}</p>

              <p>
                {t("_picture_id")}: {props.finding.original_image.filename}
              </p>
            </div>
          ) : (
            <></>
          )}

          {/* license pending */}
          {finding.license &&
          finding.license.state === "UNCHECKED" &&
          caseInfo.caseDetails.state === "ACTIVE" &&
          payment.paymentDetails.state !== "PROCESSING" ? (
            <div className={styles.image_text}>
              <p> {t("_uploaded_license_check_pending")}</p>
              <p>
                {t("_picture_id")}: {props.finding.original_image.filename}
              </p>
            </div>
          ) : (
            <></>
          )}

          {/* no license uploaded || license invalid   */}
          {!finding.license ||
          (finding.license && finding.license.state === "INVALID") ? (
            <div className={styles.image_text}>
              <p>
                {t("_price")}:{" "}
                {formatCurrency(country, props.currency, props.finding.price)}
              </p>
              <p>
                {t("_picture_id")}: {props.finding.original_image.filename}
              </p>
            </div>
          ) : (
            <></>
          )}

          <div className={styles.link_container}>
            <p>
              <a
                className={styles.link}
                href={finding.page_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("_page_link_mobile")}
              </a>
            </p>
            <p>
              <a
                className={styles.link}
                href={finding.image_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("_picture_link_mobile")}
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
