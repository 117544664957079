import React from "react";
import { Dialog } from "@material-ui/core";

function Modal(props) {
  return (
    <Dialog
      maxWidth="xl"
      open={props.show}
      onClose={props.modalClosed}
      disableEnforceFocus={props.disableEnforceFocus ? true : false}
    >
      {props.children}
    </Dialog>
  );
}

export default Modal;
