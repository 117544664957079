import React, { useState } from "react";
import clsx from "clsx";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { authActions } from "@_actions/auth.actions";
import styles from "./Drawer.module.css";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";

import MenuIcon from "@material-ui/icons/Menu";

const useStyles = makeStyles({
  list: {
    width: "100vw",
    height: "50vh",
  },
  fullList: {
    width: "auto",
  },
});

export default function TemporaryDrawer() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const auth = useSelector((state) => state.authReducer);
  const caseInfo = useSelector((state) => state.caseDetailsReducer);
  const customerDetails = useSelector((state) => state.customerReducer);

  function handleLogout() {
    dispatch(authActions.logout());
  }

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  // const handleClick = () => {
  //   setOpenSnackbar(true);
  // };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const classes = useStyles();
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === "bottom",
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {auth.user ? (
          <div>
            <div className={styles.link_container}>
              <NavLink to="caseinfo" className={styles.link_container}>
                <li>{t("_your_case")}</li>
              </NavLink>
              {caseInfo.caseDetails.state === "CANCELLED" ? (
                <></>
              ) : (
                <div>
                  <NavLink to="/resolve-case-info">
                    <li>{t("_resolve_case")}</li>
                  </NavLink>
                </div>
              )}
              {customerDetails.customer.about_us_text && (
                <NavLink to="about">
                  <li>{t("_about_us")}</li>
                </NavLink>
              )}
              {customerDetails.customer && customerDetails.customer.faq && (
                <NavLink to="FAQ">
                  <li>{t("_faq")}</li>
                </NavLink>
              )}
              <NavLink to="privacy-policy">
                <li>{t("_data_privacy_statement")}</li>
              </NavLink>
              {customerDetails.customer &&
                customerDetails.customer.imprint_link && (
                  <li>
                    <a
                      href={customerDetails.customer.imprint_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {t("_imprint")}
                    </a>
                  </li>
                )}
              <li onClick={handleLogout} className={styles.logout}>
                {t("_logout")}
              </li>
            </div>
          </div>
        ) : (
          <div>
            <div className={styles.link_container}>
              {/* <NavLink to="login"> <li onClick={handleClick} className={styles.unauth_contact_link}>Login to Contact Us</li></NavLink> */}
              {customerDetails.customer.about_us_text && (
                <NavLink to="about">
                  <li>{t("_about_us")}</li>
                </NavLink>
              )}
              {customerDetails.customer && customerDetails.customer.faq && (
                <NavLink to="FAQ">
                  <li>{t("_faq")}</li>
                </NavLink>
              )}
              <NavLink to="privacy-policy">
                <li>{t("_data_privacy_statement")}</li>
              </NavLink>
              {customerDetails.customer &&
                customerDetails.customer.imprint_link && (
                  <li>
                    {" "}
                    <a
                      href={customerDetails.customer.imprint_link}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      {t("_imprint")}
                    </a>
                  </li>
                )}
            </div>
          </div>
        )}
      </List>
    </div>
  );

  return (
    <div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="info">
          {t("_please_login_to_contact_us")}
        </Alert>
      </Snackbar>
      {["bottom"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}>
            <MenuIcon />
          </Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            <div className={styles.drawer_border}></div>
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
