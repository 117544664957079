import React from 'react';
import License from '@_components/popups/License/License';
import { useSelector } from 'react-redux';

function LicenseMobile() {
  const caseDetails = useSelector((state) => state.caseDetailsReducer);
  let caseInfo = caseDetails.caseDetails;

  return (
    <div>
      {caseInfo && caseInfo.findings ? (
        <div>
          {caseInfo.findings.map((finding) => (
            <div key={finding.id}>
              <License finding={finding} />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}

export default LicenseMobile;
