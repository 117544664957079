import Config from '@_configs/api.config';
import { authHeader } from '../@_utils/helpers';
import { clearToken } from '@_utils/helpers';

export const caseDetailsService = {
  caseDetails,
};

function caseDetails() {
  const requestOptions = {
    method: 'GET',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
  };

  return fetch(
    Config.API_ROOT + 'cases/' + localStorage.getItem('case_id'),
    requestOptions
  ).then((res) => {
    return res.json().then((data) => {
      if (!res.ok) {
        if (res.status === 401) {
          clearToken();
        }
        const error = (data && data.message) || res.status;
        return Promise.reject(error);
      }
      return data;
    });
  });
}
