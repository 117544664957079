import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./Footer.module.css";
import { NavLink } from "react-router-dom";
import { useSelector } from "react-redux";

function Footer() {
  const { t } = useTranslation();
  const customerDetails = useSelector((state) => state.customerReducer);

  return (
    <div className={styles.footer}>
      <div className={styles.footerText}>
        <p>
          {" "}
          © {new Date().getFullYear()} {customerDetails.customer.name}
        </p>
      </div>
      <div className={styles.footerLinksContainer}>
        <li>
          <NavLink to="/privacy-policy">{t("_data_privacy_statement")}</NavLink>
        </li>
        {customerDetails.customer.imprint_link && customerDetails.customer.imprint_link.trim().length > 0 ? (
          <li>
            <a
              href={customerDetails.customer.imprint_link}
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("_imprint")}
            </a>
          </li>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

export default Footer;
