import { customerConstants } from '@_constants/customer.constants';

const initialState = {
  customer: {
    available_translations: {},
    translation: 'en',
  },
  loading: false,
  message: '',
};

export function customerReducer(state = initialState, action) {
  switch (action.type) {
    case customerConstants.CUSTOMER_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case customerConstants.CUSTOMER_SUCCESS:
      state = {
        ...state,
        customer: {
          
          ...action.data,
        },
        loading: false,
      };
      break;
    case customerConstants.CUSTOMER_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
      };
      break;
    default:
      break;
  }
  return state;
}
