import Config from '@_configs/api.config';
import { createBrowserHistory } from 'history';

export const customerService = {
  customer,
};

export const history = createBrowserHistory();

export function clearToken() {
  localStorage.removeItem('user');
  localStorage.removeItem('case_id');
}

export function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        clearToken();
        history.push('/login');
      }
      if (
        response.status === 400 ||
        response.status === 404 ||
        response.status === 500
      ) {
        const error = {
          error_message: data.error_message,
          error_code: data.error_code,
        };
        return Promise.reject(error);
      } else {
        const error = (data && data.error_code) || response.status;
        return Promise.reject(error);
      }
    }
    return data;
  });
}

function customer(host, language, caseId) {
  let uri = `${Config.API_ROOT}customer/config?host=${host}&translation=${language}`;
  if (caseId) uri += `&case_id=${caseId}`;

  const requestOptions = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return fetch(uri, requestOptions).then(handleResponse);
}
