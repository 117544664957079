import Config from "@_configs/api.config";
import { authHeader } from "@_utils/helpers";

export const invoiceService = {
  downloadInvoice,
  generateInvoice,
};

function downloadInvoice(invoiceId) {
  let url =
    Config.API_ROOT +
    `cases/${localStorage.getItem("case_id")}/invoice/${invoiceId}`;

  return fetch(url, {
    method: "GET",
    headers: {
      ...authHeader(),
      "Content-Type": "application/json",
    },
  });
}

function generateInvoice() {
  let url =
    Config.API_ROOT + `cases/${localStorage.getItem("case_id")}/invoice/`;

  const requestOptions = {
    method: "POST",
    headers: {
      ...authHeader(),
    },
  };
  return fetch(url, requestOptions);
}
