import Config from '@_configs/api.config';
import { authHeader } from '../@_utils/helpers';
import { handleResponse } from '@_utils/helpers';

export const licenseService = {
  license,
  deleteLicense,
};

function license(formData, finding_id) {
  const requestOptions = {
    method: 'POST',
    headers: {
      ...authHeader(),
    },
    body: formData,
  };

  return fetch(
    Config.API_ROOT +
      'cases/' +
      localStorage.getItem('case_id') +
      '/findings/' +
      finding_id +
      '/upload_license',
    requestOptions
  ).then(handleResponse);
}

function deleteLicense(finding_id) {
  const requestOptions = {
    method: 'DELETE',
    headers: {
      ...authHeader(),
      'Content-Type': 'application/json',
    },
  };

  return fetch(
    Config.API_ROOT +
      'cases/' +
      localStorage.getItem('case_id') +
      '/findings/' +
      finding_id +
      '/license',
    requestOptions
  ).then(handleResponse);
}
