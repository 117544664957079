import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./Address.module.css";
import { billingInfoActions } from "@_actions/billing_info.actions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Close } from "@material-ui/icons";
import {
  FormControl,
  FormLabel,
  TextField,
  Typography,
} from "@material-ui/core";

function Address(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const caseInfo = useSelector((state) => state.caseDetailsReducer);
  const chargeDetails = useSelector((state) => state.chargeReducer);
  const billingReducer = useSelector((state) => state.billingInfoReducer);
  const [errorMessage, setErrorMessage] = useState(null);
  const [proceed_disabled, setProceed_disabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [showNameWarning, setShowNameWarning] = useState(false);

  const [billingAddress, setBillingAddressInternal] = useState({
    email: "",
    name: "",
    company: "",
    vat_id: "",
    reference_number: "",
    address_line_1: "",
    address_line_2: "",
    postal_code: "",
    city: "",
    country: "",
  });
  let charge = chargeDetails.charge;

  useEffect(() => {
    if (
      billingAddress &&
      ((caseInfo.caseDetails.vat_id_required &&
        billingAddress?.vat_id?.trim().length > 0) ||
        !caseInfo.caseDetails.vat_id_required) &&
      billingAddress.email &&
      billingAddress.email.trim().length > 0 &&
      billingAddress.name &&
      billingAddress.name.trim().length > 0 &&
      billingAddress.address_line_1 &&
      billingAddress.address_line_1.trim().length > 0 &&
      billingAddress.postal_code &&
      billingAddress.postal_code.trim().length > 0 &&
      billingAddress.city &&
      billingAddress.city.trim().length > 0 &&
      billingAddress.country &&
      billingAddress.country.trim().length > 0
    ) {
      setProceed_disabled(false);
    } else setProceed_disabled(true);
  }, [billingAddress, caseInfo.caseDetails.vat_id_required]);

  function setBillingAddress(value) {
    setShowNameWarning(false);
    setBillingAddressInternal(value);
  }

  useEffect(() => {
    if (!charge) {
      return <Redirect from="*" to="/caseinfo" />;
    }
    if (caseInfo && caseInfo.caseDetails.billing_address) {
      setBillingAddress({
        email: caseInfo.caseDetails.billing_address.email || "",
        name: caseInfo.caseDetails.billing_address.name || "",
        company: caseInfo.caseDetails.billing_address.company || "",
        vat_id: caseInfo.caseDetails.billing_address.vat_id || "",
        reference_number:
          caseInfo.caseDetails.billing_address.reference_number || "",
        address_line_1:
          caseInfo.caseDetails.billing_address.address_line_1 || "",
        address_line_2:
          caseInfo.caseDetails.billing_address.address_line_2 || "",
        postal_code: caseInfo.caseDetails.billing_address.postal_code || "",
        city: caseInfo.caseDetails.billing_address.city || "",
        country: caseInfo.caseDetails.country || "",
      });
    } else if (caseInfo && caseInfo.caseDetails.opponent_contact_details) {
      setBillingAddress({
        email: caseInfo.caseDetails.opponent_contact_details.email || "",
        name: caseInfo.caseDetails.opponent_contact_details.name || "",
        company: caseInfo.caseDetails.opponent_contact_details.company || "",
        vat_id: "",
        reference_number:
          caseInfo.caseDetails.opponent_contact_details.reference_number || "",
        address_line_1:
          caseInfo.caseDetails.opponent_contact_details.address_line_1 || "",
        address_line_2:
          caseInfo.caseDetails.opponent_contact_details.address_line_2 || "",
        postal_code:
          caseInfo.caseDetails.opponent_contact_details.postal_code || "",
        city: caseInfo.caseDetails.opponent_contact_details.city || "",
        country: caseInfo.caseDetails.country || "",
      });
    }
  }, [caseInfo,caseInfo.caseDetails.billing_address, charge]);

  const handleNext = () => {
    // check if eather name or company name is set
    if (!billingAddress.name || billingAddress.name.trim().length === 0) {
      setShowNameWarning(true);
      return;
    }
    // show loading indicator
    setIsLoading(true);
    //call api
    dispatch(billingInfoActions.billingInfo(billingAddress));
    // dispatch(caseDetailsActions.caseDetails());
  };

  useEffect(() => {
    if (billingReducer.message && billingReducer.message.trim().length > 0) {
      setIsLoading(false);
      setErrorMessage(t("_submission_failed"));
      dispatch(billingInfoActions.reset());
    } else if (billingReducer.billing_address_saved === true) {
      props.next();
    }
    // eslint-disable-next-line
  }, [billingReducer, t]);

  const handleClose = () => {
    setIsLoading(false);
    props.onClose();
  };

  const normalView = (
    <>
      <div className={styles.address_row}>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>
            {t("_company")}/{t("_name") + " *"}
          </FormLabel>
          <TextField
            variant="outlined"
            required
            size="small"
            value={billingAddress.name}
            type="text"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                name: e.target.value,
              });
            }}
          ></TextField>
        </FormControl>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>
            {t("_email") + " *"}
          </FormLabel>
          <TextField
            variant="outlined"
            type="email"
            required
            size="small"
            value={billingAddress.email}
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                email: e.target.value,
              });
            }}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}>
            {t("_address_line") + " 1 *"}
          </FormLabel>
          <TextField
            variant="outlined"
            id="street"
            value={billingAddress.address_line_1}
            type="text"
            size="small"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                address_line_1: e.target.value,
              });
            }}
            fullWidth
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}>
            {t("_address_line") + " 2"}
          </FormLabel>
          <TextField
            id="street"
            variant="outlined"
            value={billingAddress.address_line_2}
            type="text"
            size="small"
            fullWidth
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                address_line_2: e.target.value,
              });
            }}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <div className={styles.half_form}>
          <FormControl fullWidth>
            <FormLabel className={styles.text_label}>
              {t("_city") + " *"}
            </FormLabel>
            <TextField
              required
              size="small"
              value={billingAddress.city}
              type="text"
              onChange={(e) => {
                setBillingAddress({
                  ...billingAddress,
                  city: e.target.value,
                });
              }}
              variant="outlined"
            ></TextField>
          </FormControl>
        </div>
        <div className={`${styles.half_form} ${styles.address_container} `}>
          <FormControl style={{ width: "45%" }}>
            <FormLabel className={styles.text_label}>
              {t("_postal_code") + " *"}
            </FormLabel>
            <TextField
              required
              size="small"
              value={billingAddress.postal_code}
              type="text"
              onChange={(e) => {
                setBillingAddress({
                  ...billingAddress,
                  postal_code: e.target.value,
                });
              }}
              variant="outlined"
            ></TextField>
          </FormControl>
          <FormControl style={{ width: "25%" }}>
            <FormLabel>{t("_country")}</FormLabel>
            <p className={styles.country_text}>{billingAddress.country}</p>
          </FormControl>
        </div>
      </div>
      <div className={styles.address_row}>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>
            {" "}
            {t("_vat_id")} {caseInfo.caseDetails.vat_id_required ? " *" : ""}
          </FormLabel>
          <TextField
            value={billingAddress.vat_id}
            size="small"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                vat_id: e.target.value,
              });
            }}
            variant="outlined"
          ></TextField>
        </FormControl>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>
            {" "}
            {t("_reference_number")}
          </FormLabel>
          <TextField
            value={billingAddress.reference_number}
            size="small"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                reference_number: e.target.value,
              });
            }}
            variant="outlined"
          ></TextField>
        </FormControl>
      </div>
    </>
  );

  const mobileView = (
    <>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}>
            {t("_company")}/{t("_name") + " *"}
          </FormLabel>
          <TextField
            variant="outlined"
            required
            size="small"
            value={billingAddress.name}
            type="text"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                name: e.target.value,
              });
            }}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}>
            {t("_email") + " *"}
          </FormLabel>
          <TextField
            variant="outlined"
            type="email"
            required
            size="small"
            value={billingAddress.email}
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                email: e.target.value,
              });
            }}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}>
            {t("_address_line") + " 1 *"}
          </FormLabel>
          <TextField
            variant="outlined"
            id="street"
            value={billingAddress.address_line_1}
            type="text"
            size="small"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                address_line_1: e.target.value,
              });
            }}
            fullWidth
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl fullWidth>
          <FormLabel className={styles.text_label}>
            {t("_address_line") + " 2"}
          </FormLabel>
          <TextField
            id="street"
            variant="outlined"
            value={billingAddress.address_line_2}
            type="text"
            size="small"
            fullWidth
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                address_line_2: e.target.value,
              });
            }}
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>
            {t("_city") + " *"}
          </FormLabel>
          <TextField
            required
            size="small"
            value={billingAddress.city}
            type="text"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                city: e.target.value,
              });
            }}
            variant="outlined"
          ></TextField>
        </FormControl>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>
            {t("_postal_code") + " *"}
          </FormLabel>
          <TextField
            required
            size="small"
            value={billingAddress.postal_code}
            type="text"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                postal_code: e.target.value,
              });
            }}
            variant="outlined"
          ></TextField>
        </FormControl>
      </div>
      <div className={styles.address_row}>
        <FormControl className={styles.half_form}>
          <FormLabel>{t("_country")}</FormLabel>
          <p className={styles.country_text}> {billingAddress.country}</p>
        </FormControl>
        <FormControl className={styles.half_form}>
          <FormLabel className={styles.text_label}>
            {" "}
            {t("_vat_id")} {caseInfo.caseDetails.vat_id_required ? " *" : ""}
          </FormLabel>
          <TextField
            value={billingAddress.vat_id}
            size="small"
            onChange={(e) => {
              setBillingAddress({
                ...billingAddress,
                vat_id: e.target.value,
              });
            }}
            variant="outlined"
          ></TextField>
        </FormControl>
      </div>
      <FormControl className={styles.half_form}>
        <FormLabel className={styles.text_label}>
          {" "}
          {t("_reference_number")}
        </FormLabel>
        <TextField
          value={billingAddress.reference_number}
          size="small"
          onChange={(e) => {
            setBillingAddress({
              ...billingAddress,
              reference_number: e.target.value,
            });
          }}
          variant="outlined"
        ></TextField>
      </FormControl>
    </>
  );
  return (
    <div className={styles.address_modal}>
      <div className={styles.header_container}>
        <h2 className={styles.address_header}>{t("_billing_information")}</h2>
        <Close onClick={handleClose} style={{ marginTop: 22 }}></Close>
      </div>
      <div className={styles.billing_container}>
        <div className={styles.normal_view}> {normalView} </div>
        <div className={styles.mobile_view}> {mobileView} </div>
        <div>
          <div className={styles.actionsContainer}>
            {isLoading && !props.hideLoading ? (
              <CircularProgress />
            ) : (
              <>
                {errorMessage ? (
                  <Typography style={{ color: "red" }}>
                    {errorMessage}
                  </Typography>
                ) : (
                  <>
                    <div className={styles.nameWarningContainer}>
                      {showNameWarning ? (
                        t("_name_or_company_warning")
                      ) : (
                        <>&nbsp;</>
                      )}
                    </div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={styles.button}
                      disabled={proceed_disabled}
                    >
                      {props.button || "next"}
                    </Button>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Address;
