import React from "react";
import styles from "./Progress.module.css";
import LinearProgress from "@material-ui/core/LinearProgress";

function Progress() {
  return (
    <div className={styles.progress}>
      <LinearProgress />
    </div>
  );
}

export default Progress;
