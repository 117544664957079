import React, { useEffect, useState } from "react";
import styles from "./PrivacyPolicy.module.css";
import BackBtn from "@_components/BackBtn/BackBtn";
import { useSelector, useDispatch } from "react-redux";
import { customerActions } from "@_actions/customer.actions";
import { countryListAllIsoData } from "@_utils/helpers";

function PrivacyPolicy() {
  const dispatch = useDispatch();

  const customerDetails = useSelector((state) => state.customerReducer);

  const [country, setCountry] = useState("");

  useEffect(() => {
    dispatch(customerActions.customer(window.location.hostname));
  }, [dispatch]);

  const getCountry = (countryShort) => {
    countryListAllIsoData.map(
      (country) => country.code === countryShort && setCountry(country.name)
    );
  };

  useEffect(() => {
    if (customerDetails.customer.country) {
      getCountry(customerDetails.customer.country);
    }
  }, [customerDetails.customer.country]);

  return (
    <div className={styles.auth_mobile_container}>
      <div className={styles.privacy_policy}>
        <BackBtn />
        <div className={styles.privacy_policy_content}>
          <h3>
            Privacy Policy for the Processing of Personal Data by the Operator
            of the Settlement Portal
          </h3>

          <p>The operator of this Settlement Portal is </p>
          <p>
            {customerDetails.customer.name}
            <br></br>
            {customerDetails.customer.address_line_1} <br></br>
            {customerDetails.customer.address_line_2 && (
              <>
                {customerDetails.customer.address_line_2}
                <br></br>
              </>
            )}
            {customerDetails.customer.postal_code}{" "}
            {customerDetails.customer.city}
            <br></br>
            {country}{" "}
          </p>
          {customerDetails.customer.privacy_statement_link &&
          customerDetails.customer.privacy_statement_link.trim().length > 0 ? (
            <p>
              Please refer to our general data protection and privacy statement
              at{" "}
              <a
                href={customerDetails.customer.privacy_statement_link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {customerDetails.customer.privacy_statement_link}
              </a>
            </p>
          ) : (
            <></>
          )}
          <h3>Privacy Policy for the Processing of Personal Data by PIXRAY</h3>

          <p>
            PIXRAY GmbH, Eisenhartstraße 4, 14469 Potsdam (“PIXRAY”, “we”) hosts
            this website and software as a service (the “Site”) for the operator
            mentioned above. When you provide personal information over the
            Settlement Portal, the sole Controller of your personal information
            is the operator and PIXRAY will only act as a processor on behalf of
            the operator.
          </p>

          <p>
            However, for some purposes that are connected with the hosting,
            PIXRAY acts as a controller of its own. With this privacy policy,
            PIXRAY informs you about the personal data it collects and processes
            in this regard. Thereby, PIXRAY also fulfils its obligation to
            inform you pursuant to Article 13 General Data Protection Regulation
            (GDPR).
          </p>

          <p className={styles.privacy_policy_bold}>
            I. Identity of the Controller{" "}
          </p>
          <p>
            PIXRAY GmbH<br></br>
            Eisenhartstraße 4<br></br>
            14469 Potsdam
          </p>

          <p>
            Telephone: +49 331 288 380 00<br></br>
            Telefax: +49 331 288 380 99<br></br>
            Email: info@pixray.com
          </p>

          <p className={styles.privacy_policy_bold}>
            II. Data Protection Officer
          </p>

          <p>We have appointed a data protection officer for our company.</p>

          <p>
            Herr Benjamin Jankovic<br></br>
            Eisenhartstraße 4<br></br>
            14469 Potsdam
          </p>

          <p>
            Telephone: +49 331 288 380 00<br></br>
            Email: gdpr@pixray.com
          </p>

          <p className={styles.privacy_policy_bold}>
            III. Purposes of Processing, its Legal Basis and the Storage Period
          </p>

          <p>
            Generally, we do not store personal data while you use the Site with
            the exception that our webserver registers all connections to the
            Site automatically and collects the following technical information
            about your visit:{" "}
          </p>

          <p>
            - IP address <br></br>- Name of the files and URLs accessed<br></br>
            - Information about the transmission <br></br>- Date and time of the
            connection <br></br>- Amount of data transmitted<br></br>- Operating
            system and <br></br>- Web browser/ user agent
          </p>

          <p>
            We process this data to establish a connection to your device over
            the Internet. We store the aforementioned data in log files in order
            to ensure the security and integrity of our IT systems. The
            respective purposes of the processing also constitute our legitimate
            interests we pursue with it (Art. 6 par. 1 lit. f) GDPR). We retain
            our log files for up to three months and delete them thereafter.{" "}
          </p>

          <p className={styles.privacy_policy_bold}>
            IV. Recipients and transfers to third countries
          </p>

          <p>
            If we are not able to provide services ourselves, we use external
            service providers. These service providers are primarily providers
            of IT services, such as our hosting provider, e-mail provider or
            telecommunications provider. If not specifically mentioned elsewhere
            in this privacy policy, we do not transfer your data to third
            countries.
          </p>

          <p className={styles.privacy_policy_bold}>
            V. Rights of the data subject
          </p>

          <p>
            If the respective requirements are met, the GDPR grants you certain
            rights as a data subject.{" "}
          </p>

          <p>
            <strong>Art. 15 GDPR – Right of access: </strong>You shall have the
            right to obtain from us confirmation as to whether or not personal
            data concerning you are being processed, and, where that is the
            case, access to the personal data and certain information.{" "}
          </p>

          <p>
            <strong>Art. 16 GDPR – Right to rectification:</strong> You shall
            have the right to obtain from us without undue delay the
            rectification of inaccurate personal data concerning you. Taking
            into account the purposes of the processing, you shall have the
            right to have incomplete personal data completed, including by means
            of providing a supplementary statement.
          </p>

          <p>
            <strong>Art. 17 GDPR – Right to erasure:</strong> You shall have the
            right to obtain from us the erasure of personal data concerning you
            without undue delay.
          </p>

          <p>
            <strong>Art. 18 GDPR – Right to restriction of processing:</strong>{" "}
            You shall have the right to obtain from us the restriction of
            processing.
          </p>

          <p>
            <strong>Art. 20 GDPR – Right to data portability:</strong> You shall
            have the right to receive the personal data concerning you, which
            you have provided to us, in a structured, commonly used and
            machine-readable format and you shall have the right to transmit
            those data to another controller without hindrance from us. You
            shall also have the right to have the personal data transmitted
            directly from us to another controller, where technically feasible.
          </p>

          <p>
            <strong>
              Art. 77 GDPR – Right to lodge a complaint with a supervisory
              authority:
            </strong>{" "}
            Without prejudice to any other administrative or judicial remedy,
            you shall have the right to lodge a complaint with a supervisory
            authority, in particular in the Member State of your habitual
            residence, place of work or place of the alleged infringement if you
            consider that the processing of personal data relating to you
            infringes the GDPR.
          </p>

          <p>VI. Especially right to object and withdrawal of consent</p>

          <p>
            <strong>Art. 21 GDPR – Right to Object:</strong> You shall have the
            right to object, on grounds relating to your particular situation,
            at any time to processing of personal data concerning you, which is
            based on legitimate interests or for the performance of a task
            carried out in the public interest or in the exercise of official
            authority vested in the controller In such case, we shall no longer
            process the personal data unless we demonstrate compelling
            legitimate grounds for the processing, which override your
            interests, rights and freedoms or where the processing is necessary
            for the establishment, exercise or defence of legal claims.
          </p>

          <p>
            Where personal data are processed for direct marketing purposes, you
            shall have the right to object at any time for such marketing, which
            includes profiling to the extent that it is related to such direct
            marketing.
          </p>

          <p>
            If you wish to object to any processing of data, you may send us an
            email to one of our aforementioned email addresses.
          </p>

          <p>
            <strong>Art. 7 par. 3 GDPR – Withdrawal of Consent:</strong> If you
            have given us your consent, you have the right to withdraw your
            consent at any time. In case of withdrawal, all processing based on
            your consent before your withdrawal will remain lawful.
          </p>

          <p className={styles.privacy_policy_bold}>
            VII. Obligation to provide us with personal data
          </p>

          <p>
            You have no statutory or contractual obligation to provide us with
            any personal data. However, we may not be able to provide you with
            our services if you decide not to do so.
          </p>

          <p className={styles.privacy_policy_bold}>
            VIII. Existence of automated individual decision-making, including
            profiling
          </p>

          <p>
            We do not use automated individual decision-making, including
            profiling pursuant to Art. 22 GDPR, which produces legal effects
            concerning you or similarly significantly affects you.{" "}
          </p>

          <p>Potsdam, March 2021</p>
        </div>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
