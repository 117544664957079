import { chargeConstants } from "@_constants/charge.constants";
import { chargeService } from "@_services/charge.service";

export const chargeActions = {
  charge,
};

function charge() {
  return (dispatch) => {
    dispatch(request());
    chargeService.charge().then(
      (data) => {
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };
}

function request() {
  return { type: chargeConstants.CHARGE_REQUEST };
}
function success(data) {
  return { type: chargeConstants.CHARGE_SUCCESS, data };
}
function failure(error) {
  return { type: chargeConstants.CHARGE_FAILURE, error };
}
