import Config from "@_configs/api.config";
import { handleResponse } from "@_utils/helpers";

export const authService = {
  login,
};

function login(caseNumber, pin, host) {
  let uri = `${Config.API_ROOT}cases/${caseNumber}/authenticate?host=${host}`;

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "text/plain" },
    body: pin,
  };

  return fetch(uri, requestOptions)
    .then(handleResponse)
    .then((user) => {
      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("case_id", caseNumber);
      return user;
    });
}
