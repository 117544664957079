import { chargeConstants } from '../@_constants/charge.constants';

const initialState = {
  charge: {},
  loading: false,
  message: '',
};

export function chargeReducer(state = initialState, action) {
  switch (action.type) {
    case chargeConstants.CHARGE_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case chargeConstants.CHARGE_SUCCESS:
      state = {
        ...state,
        charge: action.data,
        loading: false,
      };
      break;
    case chargeConstants.CHARGE_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    default:
      break;
  }
  return state;
}
