import { invoiceConstants } from "@_constants/invoice.constants";

const initialState = {
  invoice: [],
  loading: false,
  message: "",
  success: false,
  status: {
    INVOICE_DOWNLOAD: "",
    INVOICE_GENERATE: "",
  },
};

export function invoiceReducer(state = initialState, action) {
  switch (action.type) {
    // TEMPLATE_CLEAR_STATUS_MESSAGE
    case invoiceConstants.INVOICE_CLEAR_STATUS_MESSAGE:
      state = {
        ...state,
        loading: false,
        success: true,
        message: "",
        status: { ...state.status, [action.stateName]: "" },
      };

      break;

    // INVOICE DWONLOAD REQUEST
    case invoiceConstants.INVOICE_DOWNLOAD_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        success: false,
        status: { ...state.status, INVOICE_DOWNLOAD: "request" },
      };
      break;
    case invoiceConstants.INVOICE_DOWNLOAD_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        success: true,
        status: { ...state.status, INVOICE_DOWNLOAD: "success" },
      };
      break;
    case invoiceConstants.INVOICE_DOWNLOAD_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, INVOICE_DOWNLOAD: action.error },
      };
      break;

    // INVOICE GENERATE REQUEST
    case invoiceConstants.INVOICE_GENERATE_REQUEST:
      state = {
        ...state,
        loading: true,
        message: "",
        success: false,
        status: { ...state.status, INVOICE_GENERATE: "request" },
      };
      break;
    case invoiceConstants.INVOICE_GENERATE_SUCCESS:
      state = {
        ...state,
        loading: false,
        message: "",
        success: true,
        status: { ...state.status, INVOICE_GENERATE: "success" },
      };
      break;
    case invoiceConstants.INVOICE_GENERATE_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
        success: false,
        status: { ...state.status, INVOICE_GENERATE: action.error },
      };
      break;

    default:
      break;
  }
  return state;
}
