import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./OriginalImage.module.css";
import classnames from "classnames";
import { formatCurrency } from "@_utils/helpers";

function OriginalImage(props) {
  const { t } = useTranslation();

  return (
    <>
      {props.finding.license && props.finding.license.state === "VALID" ? (
        <></>
      ) : (
        <div className={styles.original_image_container}>
          <div className={styles.charge_row}>
            <div className={classnames(styles.charge_column)}>
              <div className={styles.image_box}>
                <img
                  src={props.finding.original_image.original_image_url}
                  className={styles.original_image}
                  alt="original img"
                />
              </div>
            </div>
            <div className={styles.charge_column}></div>
            <div className={styles.charge_column}>
              <p className={styles.label}>{t("_price")} </p>
              <p className={styles.field}>
                {formatCurrency(
                  props.country,
                  props.currency,
                  props.finding.price
                )}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default OriginalImage;
