import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styles from "./PaymentConfirmation.module.css";
import BackBtn from "@_components/BackBtn/BackBtn";
import { caseDetailsActions } from "../../@_actions/case_details.actions";
import { chargeActions } from "../../@_actions/charge.actions";
import classnames from "classnames";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import { formatCurrency } from "@_utils/helpers";

function PaymentConfirmation() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const caseDetails = useSelector((state) => state.caseDetailsReducer);
  const chargeDetails = useSelector((state) => state.chargeReducer);

  const [totalAmountPaid, setTotalAmountPaid] = useState("");

  useEffect(() => {
    dispatch(caseDetailsActions.caseDetails());
  }, [dispatch]);

  useEffect(() => {
    dispatch(chargeActions.charge());
  }, [dispatch]);

  let charge = chargeDetails.charge;

  useEffect(() => {
    if (
      charge &&
      charge.settlement_offer &&
      Date.parse(charge.settlement_offer.settlement_date) >= new Date()
    ) {
      setTotalAmountPaid(parseFloat(charge.final_amount_gross_settlement));
    } else if (charge && charge.final_amount_gross_no_settlement) {
      setTotalAmountPaid(parseFloat(charge.final_amount_gross_no_settlement));
    }
  }, [charge, dispatch]);

  let billingInfo = caseDetails.caseDetails.billing_address;

  let country = caseDetails.caseDetails && caseDetails.caseDetails.country;

  return (
    <div>
      <BackBtn />
      <div className={styles.confirmation_container}>
        <div className={styles.confirmation_header}>
          <CheckCircleIcon className={styles.check_circle_icon} />
          <h2 className={styles.payment_success_text}>
            {t("_payment_processed_text")}
          </h2>
        </div>
        <div className={styles.confirmation}>
          <h2>
            {t("_we_appriciate_you_taking_the_time_to_resolve_the_issue")}
          </h2>
          <div className={styles.payment_details_container}>
            {caseDetails.caseDetails &&
            caseDetails.caseDetails.billing_address ? (
              <div>
                <div>
                  <h3>{t("_billing_information")}</h3>
                </div>
                <div className={styles.billing_col}>
                  <p>{billingInfo.name}</p>
                </div>
                <div className={styles.billing_col}>
                  <p>{billingInfo.email}</p>
                </div>
                {billingInfo.company ? (
                  <div className={styles.billing_col}>
                    <p>{billingInfo.company}</p>
                  </div>
                ) : null}
                <div className={styles.billing_col}>
                  <p>{billingInfo.street_address}</p>
                </div>
                <div className={styles.billing_col}>
                  <p>{billingInfo.city}</p>
                </div>
                <div className={styles.billing_col}>
                  <p>{billingInfo.postal_code}</p>
                </div>
                <div className={styles.billing_col}>
                  <p>{billingInfo.country}</p>
                </div>
                {billingInfo.vat_id ? (
                  <div className={styles.billing_col}>
                    <p>{billingInfo.vat_id}</p>
                  </div>
                ) : null}
              </div>
            ) : null}
            <div>
              <h3>{t("_total")}:</h3>
            </div>
            <div
              className={classnames(
                styles.billing_col,
                styles.billing_col_total
              )}
            >
              <p>{formatCurrency(country, charge.currency, totalAmountPaid)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentConfirmation;
