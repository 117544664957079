import { caseDetailsConstants } from "../@_constants/case_details.constants";
import { billingInfoConstants } from "@_constants/billing_info.constants";

const initialState = {
  caseDetails: {},
  loading: false,
  message: "",
};

export function caseDetailsReducer(state = initialState, action) {
  switch (action.type) {
    case caseDetailsConstants.CASE_DETAILS_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case caseDetailsConstants.CASE_DETAILS_SUCCESS:
      state = {
        ...state,
        caseDetails: action.data,
        loading: false,
      };
      break;
    case caseDetailsConstants.CASE_DETAILS_FAILURE:
      state = {
        ...state,
        loading: false,
      };
      break;
    case billingInfoConstants.BILLING_INFO_SUCCESS:
      state = {
        ...state,
        caseDetails: {
          ...state.caseDetails,
          billing_address: action.data,
        },
      };
      break;
    default:
      break;
  }
  return state;
}
