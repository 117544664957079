import { billingInfoConstants } from "../@_constants/billing_info.constants";

const initialState = {
  billingInfo: {},
  loading: false,
  billing_address_saved: false,
  message: "",
};

export function billingInfoReducer(state = initialState, action) {
  switch (action.type) {
    case billingInfoConstants.BILLING_INFO_REQUEST:
      state = {
        ...state,
        loading: true,
      };
      break;
    case billingInfoConstants.BILLING_INFO_SUCCESS:
      state = {
        ...state,
        billingInfo: action.data,
        loading: false,
        billing_address_saved: true,
      };
      break;
    case billingInfoConstants.BILLING_INFO_FAILURE:
      state = {
        ...state,
        loading: false,
        message: action.error,
      };
      break;
    case billingInfoConstants.BILLING_INFO_RESET:
      state = {
        ...state,
        loading: false,
        message: "",
        billing_address_saved: false,
      };
      break;
    default:
      break;
  }
  return state;
}
