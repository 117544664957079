import { invoiceConstants } from "@_constants/invoice.constants";
import { invoiceService } from "@_services/invoice.service";
import { handleDownloadFile } from "@_utils/helpers";

export const invoiceActions = {
  downloadInvoice,
  generateInvoice,
  clearStatusMessage,
};

function clearStatusMessage(stateName) {
  return (dispatch) => {
    dispatch(clear(stateName));
  };

  function clear(stateName) {
    return { type: invoiceConstants.INVOICE_CLEAR_STATUS_MESSAGE, stateName };
  }
}

function downloadInvoice(invoiceId) {
  return (dispatch) => {
    dispatch(request());
    invoiceService.downloadInvoice(invoiceId).then(
      async (response) => {
        const blob = await response.blob().catch((error) => {
          console.log(error);
          dispatch(failure(error));
        });
        const contentDsiposition = response.headers.get("content-disposition");
        if (!contentDsiposition) return;
        const fileName = contentDsiposition.split('"')[1];
        handleDownloadFile(blob, fileName);

        dispatch(success());
      },
      (error) => {
        console.log(error);
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.INVOICE_DOWNLOAD_REQUEST };
  }
  function success() {
    return { type: invoiceConstants.INVOICE_DOWNLOAD_SUCCESS };
  }
  function failure(error) {
    return { type: invoiceConstants.INVOICE_DOWNLOAD_FAILURE, error };
  }
}

function generateInvoice() {
  return (dispatch) => {
    dispatch(request());
    invoiceService.generateInvoice().then(
      async (response) => {
        if (response.status && response.status === 500) {
          dispatch(failure("Most likely no Invoice template is set."));
          return;
        }
        const blob = await response.blob();
        const contentDsiposition = response.headers.get("content-disposition");
        if (!contentDsiposition) return;
        const fileName = contentDsiposition.split('"')[1];
        handleDownloadFile(blob, fileName);
        dispatch(success());
      },
      (error) => {
        console.log(error);
        dispatch(failure(error));
      }
    );
  };
  function request() {
    return { type: invoiceConstants.INVOICE_GENERATE_REQUEST };
  }
  function success() {
    return { type: invoiceConstants.INVOICE_GENERATE_SUCCESS };
  }
  function failure(error) {
    return { type: invoiceConstants.INVOICE_GENERATE_FAILURE, error };
  }
}
