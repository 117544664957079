import { caseDetailsConstants } from "@_constants/case_details.constants";
import { caseDetailsService } from "@_services/case_details.service";

export const caseDetailsActions = {
  caseDetails,
};

function caseDetails() {
  return (dispatch) => {
    dispatch(request());
    caseDetailsService.caseDetails().then(
      (data) => {
     
        dispatch(success(data));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error.toString()));
      }
    );
  };
}

function request() {
  return { type: caseDetailsConstants.CASE_DETAILS_REQUEST };
}
function success(data) {
  return { type: caseDetailsConstants.CASE_DETAILS_SUCCESS, data };
}
function failure(error) {
  return { type: caseDetailsConstants.CASE_DETAILS_FAILURE, error };
}
