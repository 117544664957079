import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import styles from "./StripePaymentBox.module.css";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import { formatCurrency } from "@_utils/helpers";

import ErrorIcon from "@material-ui/icons/Error";
import CircularProgress from "@material-ui/core/CircularProgress";

function StripePaymentBox(props) {
  const { t } = useTranslation();
  const payment = useSelector((state) => state.paymentReducer);
  const [paymentIntent, setPaymentIntent] = useState();

  // Initialize an instance of stripe.
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState(null);
  const [noPaymentInFlight, setNoPaymentInFlight] = useState(true);
  const [requiredBillingAddress, setRequiredBillingAddress] = useState(false);

  const handleSubmitBillingInfo = () => {
    if (!payment.message && requiredBillingAddress) {
      //dispatch(billingInfoActions.billingInfo(props.billingAddress));
    }
  };

  useEffect(() => {
    if (
      props.billingAddress.email === "" ||
      props.billingAddress.name === "" ||
      props.billingAddress.address_line_1 === "" ||
      props.billingAddress.postal_code === "" ||
      props.billingAddress.city === "" ||
      props.billingAddress.country === ""
    ) {
      setRequiredBillingAddress(false);
    }
  }, [props.billingAddress]);

  useEffect(() => {
    if (
      props.billingAddress.email &&
      props.billingAddress.name &&
      props.billingAddress.address_line_1 &&
      props.billingAddress.postal_code &&
      props.billingAddress.city &&
      props.billingAddress.country
    ) {
      setRequiredBillingAddress(true);
    }
  }, [props.billingAddress]);

  if (!stripe || !elements) {
    return <></>;
  } else {
    if (!elements.getElement("payment")) {
      var pe = elements.create("payment", {
        defaultValues: {
          billingDetails: {
            name: props.billingAddress.name,
            email: props.billingAddress.email,
            address: {
              line1: props.billingAddress.address_line_1,
              line2: props.billingAddress.address_line_2,
              city: props.billingAddress.city,
              postal_code: props.billingAddress.postal_code,
              country: props.billingAddress.country,
            },
          },
        },
        style: { base: { fontSize: "13px" } },
      });
      window.setTimeout(() => pe.mount("#pe-mount"), 1000);
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setErrorMessage(null);
    setNoPaymentInFlight(false);
    if (!payment.message && requiredBillingAddress) {
      //dispatch(billingInfoActions.billingInfo(props.billingAddress));
    }

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    const result = await stripe.confirmPayment({
      //`Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${window.location.origin}/payment-confirmation`,
      },
    });

    if (result.error) {
      // Show error to your customer (e.g., payment details incomplete)
      setErrorMessage(result.error);
      setNoPaymentInFlight(true);
      console.log(result.error.message);
      return;
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      console.log(result);
      setPaymentIntent(paymentIntent);
    }
  };

  return (
    <div>
      {" "}
      <form onSubmit={handleSubmit}>
        <div id="pe-mount" />
        <div className={styles.errorMessage}>
          {errorMessage != null && noPaymentInFlight && (
            <div className={styles.errorIcon}>
              <ErrorIcon />
            </div>
          )}{" "}
          {errorMessage != null && errorMessage.message}
          {!noPaymentInFlight && <CircularProgress />}
          {payment.message && <>{t("_failed_prepare_payment_text")}</>}
        </div>
        <div className={styles.payment_btn_container}>
          {" "}
          {!stripe || !noPaymentInFlight || !requiredBillingAddress ? (
            <button className={styles.payment_btn_disabled} disabled>
              {t("_pay")}{" "}
              {formatCurrency(props.country, props.currency, props.finalAmount)}
            </button>
          ) : (
            <button
              className={styles.payment_btn}
              onClick={handleSubmitBillingInfo}
            >
              {t("_pay")}{" "}
              {formatCurrency(props.country, props.currency, props.finalAmount)}
            </button>
          )}
        </div>
      </form>
    </div>
  );
}

export default StripePaymentBox;
