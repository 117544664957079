import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from '@_core/serviceWorker';
import Routes from '@_core/Routes';
import { Store } from '@_core/Store';
import './index.css';

ReactDOM.render(
  <Suspense fallback="loading">
    <Provider store={Store}>
      <Routes />
    </Provider>
  </Suspense>,
  document.getElementById('root')
);

serviceWorker.unregister();
